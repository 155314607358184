//场景其它信息与作业凭证(车辆)
export const sceneColumnData = [
  {
    fieldName: "vin",
    display: true,
    fieldLabel: "车架号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "vehicleBrandName",
    display: true,
    fieldLabel: "车辆品牌",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  // {
  //   fieldName: "vehicleTypeGb",
  //   display: true,
  //   fieldLabel: "车辆类型",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // },
  {
    fieldName: "vehicleMarkerName",
    display: true,
    fieldLabel: "车型分类",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "insideLength",
    display: true,
    fieldLabel: "车厢长度（cm）",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "overallLength",
    display: true,
    fieldLabel: "车体长(CM)",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "overallWidth",
    display: true,
    fieldLabel: "车体宽(CM)",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    mode: "color",
    color: "#79a3f2"
  },
  {
    fieldName: "overallHeight",
    display: true,
    fieldLabel: "车体高(CM)",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  }
  // {
  //   fieldName: "workCount",
  //   display: true,
  //   fieldLabel: "作业图片",
  //   width: 80,
  //   disabled: false,
  //   fixed: false,
  //   align: "center"
  // }
];
export const otherSceneColumnData = [
  {
    fieldName: "overallLength",
    display: true,
    fieldLabel: "长",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "overallWidth",
    display: true,
    fieldLabel: "宽",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    mode: "color",
    color: "#79a3f2"
  },
  {
    fieldName: "overallHeight",
    display: true,
    fieldLabel: "高",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
//安装要求表头
export const InstallRequireColumn = [
  {
    fieldName: "installationRequirements",
    display: true,
    fieldLabel: "安装要求",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "aircondition",
    display: true,
    fieldLabel: "空调",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "cooler",
    display: true,
    fieldLabel: "冷机",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "power",
    display: true,
    fieldLabel: "电源接入",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "accessRequestName",
    display: true,
    fieldLabel: "政府对接要求",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "accessRequestQyName",
    display: true,
    fieldLabel: "企业对接要求",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "versionRequirements",
    display: true,
    fieldLabel: "版本要求",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "isCard",
    display: true,
    fieldLabel: "卡要求",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "hydraulic",
    display: true,
    fieldLabel: "液压",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "cutoilelec",
    display: true,
    fieldLabel: "断油断电",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "centerlock",
    display: true,
    fieldLabel: "中控锁",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "isMan",
    display: true,
    fieldLabel: "板卡控制",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "antennalocation",
    display: true,
    fieldLabel: "GPS天线位置",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  }
];
//新装加装作业明细表头
export const installDetailColumn1 = [
  {
    fieldName: "thirdClassName",
    display: true,
    fieldLabel: "商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "firstClassName",
    display: true,
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "secondClassName",
    display: true,
    fieldLabel: "商品开票名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "equipOpName",
    display: true,
    fieldLabel: "设备操作",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "installStatusName",
    display: true,
    fieldLabel: "安装状态",
    width: 100,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "equCombination",
    display: true,
    fieldLabel: "安装组合",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "equipCode",
    display: true,
    fieldLabel: "设备编号",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "cardOpName",
    display: true,
    fieldLabel: "卡操作",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "simNo",
    display: true,
    fieldLabel: "SIM卡号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "simBindingDay",
    display: true,
    fieldLabel: "原SIM卡绑定时长",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "isChangeSimName",
    display: true,
    fieldLabel: "是否需要更换SIM卡",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "commids",
    display: true,
    fieldLabel: "中心识别码",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "insbodyName",
    display: true,
    fieldLabel: "安装位置",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  }
];
//拆卸维修巡检作业明细表头
export const installDetailColumn2 = [
  {
    fieldName: "equipOpName",
    display: true,
    fieldLabel: "设备操作",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "cardOpName",
    display: true,
    fieldLabel: "卡操作",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "thirdClassName",
    display: true,
    fieldLabel: "商品名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "firstClassName",
    display: true,
    fieldLabel: "商品分类",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "secondClassName",
    display: true,
    fieldLabel: "商品开票名称",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  // {
  //   fieldName: "installStatusName",
  //   display: true,
  //   fieldLabel: "安装状态",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center",
  //   class: ""
  // },
  {
    fieldName: "equCombination",
    display: true,
    fieldLabel: "安装组合",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "equipCode",
    display: true,
    fieldLabel: "新设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "oldEquipCode",
    display: true,
    fieldLabel: "旧设备编号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "dismantleStatusName",
    display: true,
    fieldLabel: "原设备去向",
    width: 140,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "cardOpName",
    display: true,
    fieldLabel: "卡操作",
    width: 120,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "simNo",
    display: true,
    fieldLabel: "新SIM卡号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "oldSimNo",
    display: true,
    fieldLabel: "旧SIM卡号",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "simBindingDay",
    display: true,
    fieldLabel: "原SIM卡绑定时长",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "isChangeSimName",
    display: true,
    fieldLabel: "是否需要更换SIM卡",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "commids",
    display: true,
    fieldLabel: "中心识别码",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  }
  // {
  //   fieldName: "insbodyName",
  //   display: true,
  //   fieldLabel: "安装位置",
  //   width: 160,
  //   disabled: false,
  //   fixed: false,
  //   align: "center",
  //   class: ""
  // }
];
//费用表头
export const feeColumn = [
  {
    fieldName: "feeName",
    display: true,
    fieldLabel: "收费项目",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "feeTypeName",
    display: true,
    fieldLabel: "收费类型",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "taxGiveTypeName",
    display: true,
    fieldLabel: "收款方式",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "currencyName",
    display: true,
    fieldLabel: "币种",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "paidIn",
    display: true,
    fieldLabel: "收费金额",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
export const detectionResultColumn = [
  {
    fieldName: "inspectionName",
    display: true,
    fieldLabel: "检测项",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center",
    class: ""
  },
  {
    fieldName: "standardDesc",
    display: true,
    fieldLabel: "达标值",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "resultValue",
    display: true,
    fieldLabel: "检测值",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "dataTime",
    display: true,
    fieldLabel: "数据上报时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  },
  {
    fieldName: "inspectionTime",
    display: true,
    fieldLabel: "检测时间",
    width: 160,
    disabled: false,
    fixed: false,
    align: "center"
  }
];
